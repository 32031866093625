@media (min-width: 768px) {
  .container {
    padding: 40px 90px;
    margin: 0 auto;
  }

  header {
    &.default {
      height: 100px;
      min-height: 100px;
      background-color: transparent;
    }
    &.scrolled {
      height: 50px;
      min-height: 50px;
      background-color: #555;
      @media (min-width: 1024px) {
        .logo {
          width: 11%;
        }
      }
      @media (min-width: 1200px) {
        .logo {
          width: 8%;
        }
      }
    }
    .container {
      padding: 0 90px;
    }
    .logo {
      height: auto;
      width: 14%;
      transition: 0.5s;
      @media (min-width: 1200px) {
        width: 10%;
      }
    }

    nav {
      display: flex;
      margin: auto 0;
  
      a {
        display: inline-block;
        padding: 0 1.5rem;
        @media (max-width: 1023px) {
          &:last-child {
            padding: 0 0 0 1.5rem;
          }
        }
        @media (min-width: 1200px) {
          padding: 0 1.5rem;
        }
      }
    }    
  }

  #about {
    @media (min-width: 1024px) {
      &.container {
        padding: 40px 150px;
      }
    }
    .intro {
      margin: 0 auto;
      padding: 0 40px;
    }
    .skills {
      .skill-category {
        display: flex;
        justify-content: space-between;
        margin-right: 90px;
        margin-left: 90px;
        .skill-category-title {
          border-bottom: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25%;
          @media (min-width: 1440px) {
            width: 15%;
          }
          margin: 0;
          border-right: 2px solid #cecece;
          h4 {
            font-size: x-large;
            margin: auto 0;
            padding: 0;
            width: 100%;
          }
        }
        .skill-category-body {
          width: 75%;
          @media (min-width: 1440px) {
            width: 85%;
          }
          .skill {
            width: 85px;
            margin: 35px 40px;
            img {
              width: 100%;
              height: 85px;
            }
          }
        }
      }
    }
  }

  #projects {
    .grid-container  {
      grid-auto-flow: row;
      grid-gap: 40px;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }
  }

  #contact {
    .email {
      font-size: 30px;
    }
  }
}
