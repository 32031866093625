html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Maven Pro', sans-serif;
}

.container {
	padding: 30px;
	margin: 0 auto;
}

a {
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

header {
  height: auto;
  min-height: 50px;
  background-color: #555;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.5s;
  z-index: 10;

  .container {
    position: relative;
    height: 100%;
    padding: 10px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    margin: auto 0;
    cursor: pointer;
    text-align: center;
    height: 100%;
    width: 26%;
    svg {
      height: 100%;
      width: 100%;
      padding-top: 5px;
      path {
        fill: white;
        dominant-baseline: central;
        transition: 0.3s;
      }
    }
    
    &:hover svg path {
      fill: orange;
    }
  }
  
  nav {
    font-weight: bold;
    &#toggleMenu {
    position: absolute;
    right: 0;
    left: 0;
    top: 50px;
    height: 0;
    overflow: hidden;
    background-color: #555;
    transition: 0.3s;
      &.open {
        height: 110px;
      }
      a.smallNavLink {
        display: block;
        text-decoration: none;
        text-align: center;
        color: white;
        transition: 0.2s;
        margin: 0;
        font-size: 20px;
        line-height: 35px;
        border-radius: 0;
        border-top: 1px solid #777;
      }
    }
    a {
      border-radius: 3px;
      margin: 5px 0;
      color: white;
      font-weight: bold;
      transition: 0.3s;
      &:hover {
        color: orange;
      }
    }
  }
}

main {
  width: 100%;
}

#landing {
  color: white;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &::after {
    content: '';
    background-color: rgba(0,0,0,.3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.landing-title {
  user-select: none;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 0 10px;
  z-index: 1;
  cursor: pointer;

  h1 {
    margin: 1rem 0;
    font-size: 5rem;
    @media (max-width: 576px) {
      font-size: 3rem;
    }
  }

  h2 {
    margin: 1rem 0;
    font-size: 3rem;
    @media (max-width: 576px) {
      font-size: 2rem;
    }
  }
}

section {
  text-align: center;
}

.headline {
  display: inline-block;
  font-size: 35px;
  text-align: center;
  margin: 20px 0 55px;
  position: relative;
  z-index: 0;
  &::after {
    position: absolute;
    content: "";
    display: block;
    bottom: -5px;
    left: 25%;
    width: 50%;
    height: 4px;
    background-color: forestgreen;
  }
}

#about {
  min-height: 100vh;
  h3 {
    font-size: x-large;
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
  }
  .intro {
    padding: 0 10px;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 25px;
    max-width: 750px;
    h3:first-child {
      margin-top: 0;
    }
    h3 {
      margin-top: 40px;
    }
    p {
      color: lighten(#000000, 30);
    }
  }
  .skills {
    margin-top: 20px;
    width: 100%;
    .skill-category {
      margin: 40px auto;
      border: 2px solid #cecece;
      border-radius: 10px;
      .skill-category-title {
        border-bottom: 2px solid #cecece;
        margin-bottom: 10px;
        h4 {
          margin: 5px auto;
          font-size: large;
          font-weight: normal;
          text-align: center;
        }
      }
    
      .skill-category-body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .skill {
          margin: 10px;
          width: 70px;
          img {
            margin: 0 auto;
            width: 100%;
            height: 60px;
            object-fit: contain;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

#projects {
  min-height: 100vh;
  background-color: #ccd8cc;
  .grid-container {
    margin-top: 15px;
    display: grid;
    grid-gap: 25px;
    justify-content: center;

    .col {
      background-color: #ddd;
      border: 1px solid #666;
      .img-frame {
        width: 100%;
        height: 210px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 1s;
        }
        &:hover img {
          transform: scale(1.3);
        }
      }
      .description {
        text-align: left;
        padding: 0 15px;
        font-size: 17px;

        p {
          min-height: 70px;
        }

        ul {
          min-height: 60px;
          margin-top: 5px;
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: flex-start;
          li {
            display: inline-block;
            border: 1px solid black;
            border-radius: 5px;
            text-align: center;
            padding: 0 3px;
            margin: 0 3px 7px;
          }
        }
      }
      .project-links {
        margin: 10px 0 15px;
        display: flex;
        justify-content: space-evenly;
        a {
          display: inline-block;
          border-radius: 5px;
          padding: 7px 9px;
          text-align: center;
          transition: .3s;
        }
        .website {
          background-color: darken(orange, 5);
          color: white;
        }
        .website:hover {
          transform: scale(1.2);
        }
        .github {
          background-color: #333;
          color: white;
          i {
            margin-right: 5px;
          }
        }
        .github:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

#contact {
  margin-bottom: 3rem;
  margin: 0 auto;
  text-align: center;

  .email {
    font-size: 25px;
    margin-bottom: 30px;
    a {
      position: relative;
      color: #222;
      &:after {
        content: "";
        position: absolute;
        background-color: #222;
        height: 2px;
        width: 0;
        left: 50%;
        bottom: 0;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
        transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
      }
      &:hover:after {
        width: 100%;
      }
    }
  }

  .social-media {
    display: flex;
    justify-content: center;

    a {
      margin: 0 5px;
    }

    i {
      color: gray;
    }
  }
}

footer {
  text-align: center;
  margin: 2rem auto;
}