#burger-icon {
  position: relative;
  cursor: pointer;
  width: 25px;
  padding: 10px 0;
  z-index: 10;
  background-color: transparent;
  border: none;
  span {
    position: absolute;
    display: block;
    background-color: #fff;
    height: 3px;
    width: 50%;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  span:nth-child(even) {
    left: 50%;
  }
  span:nth-child(odd) {
    left: 0;
  }
  span:nth-child(1), span:nth-child(2) {
    top: 0;
  }
  span:nth-child(3), span:nth-child(4) {
    top: 8px;
  }
  span:nth-child(5), span:nth-child(6) {
    top: 16px;
  }
}

#burger-icon.burger-open {
  span:nth-child(1), span:nth-child(6) {
    transform: rotate(45deg);
  }
  span:nth-child(2), span:nth-child(5) {
    transform: rotate(-45deg);
  }
  span:nth-child(1) {
    left: 2px;
    top: 4px;
  }
  span:nth-child(2) {
    left: calc(50% - 2px);
    top: 4px;
  }
  span:nth-child(3) {
    left: -50%;
    opacity: 0;
    width: 0;
  }
  span:nth-child(4) {
    left: 150%;
    opacity: 0;
    width: 0;
  }
  span:nth-child(5) {
    left: 2px;
    top: 12px;
  }
  span:nth-child(6) {
    left: calc(50% - 2px);
    top: 12px;
  }
}
